import React from 'react'
import { Box, Grid } from '@mui/material'

import LoanCommitteeReportHeader from '../../components/LoanCommitteeReportHeader'
import LCRCommentary from '../../components/LCRCommentary'
import { LCRCommentarySections } from '@common/constants/client'

const LoanCommitteeReportTransactionPage = () => {
  return (
    <Box py={1} pr={2}>
      <LoanCommitteeReportHeader />

      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <LCRCommentary section={LCRCommentarySections.FieldExamReceivables} />
        </Grid>
        <Grid item xs={12}>
          <LCRCommentary section={LCRCommentarySections.FieldExamPayables} />
        </Grid>
        <Grid item xs={12}>
          <LCRCommentary section={LCRCommentarySections.FieldExamInventory} />
        </Grid>
        <Grid item xs={12}>
          <LCRCommentary section={LCRCommentarySections.FieldExamMiscellaneous} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default LoanCommitteeReportTransactionPage
