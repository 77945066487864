import { ClientEntityRelationshipType } from '../interfaces/integration'

export const LOAN_TYPES = {
  ar: 'AR',
  arAndInventory: 'AR & Inventory',
  inventory: 'Inventory',
}

export const LOAN_TYPE_MAP = {
  'AR and Inventory': LOAN_TYPES.arAndInventory,
  'AR Only': LOAN_TYPES.ar,
  'Inventory Only': LOAN_TYPES.inventory,
}

export const FREQUENCY_TYPES = {
  atFunding: 'At Funding',
  monthly: 'Monthly',
  quarterly: 'Quarterly',
  semiAnnually: 'Semi-annually',
  annually: 'Annually',
  adHoc: 'Ad-hoc',
}

export const REPORTING_DOCUMENT_TYPES = {
  apAging: 'AP Aging',
  arAging: 'AR Aging',
  inventoryDetail: 'Inventory Detail',
  financials: 'Financials',
  financialProjections: 'Financial Projections',
  salesBySKU: 'Sales By SKU',
  arGeneralLedger: 'AR General Ledger',
  bankTransactions: 'Bank Transactions',
  capTable: 'Cap Table',
}

export const ANALYSIS_QUEUE_TYPES = Object.values(REPORTING_DOCUMENT_TYPES).filter(
  (type) =>
    ![
      REPORTING_DOCUMENT_TYPES.arAging,
      REPORTING_DOCUMENT_TYPES.apAging,
      REPORTING_DOCUMENT_TYPES.inventoryDetail,
    ].includes(type),
)

export enum ReportingDocumentStatuses {
  InReview = 'in review',
  Due = 'due',
  Overdue = 'overdue',
}

export const REPORTING_DOCUMENT_TYPES_BASE_FOLDER_PATH = {
  [REPORTING_DOCUMENT_TYPES.financials]: 'Financials/Actuals',
  [REPORTING_DOCUMENT_TYPES.financialProjections]: 'Financials/Projections',
  [REPORTING_DOCUMENT_TYPES.salesBySKU]: 'SalesBySKU',
  [REPORTING_DOCUMENT_TYPES.arGeneralLedger]: 'GeneralLedger',
  [REPORTING_DOCUMENT_TYPES.bankTransactions]: 'BankStatements',
  [REPORTING_DOCUMENT_TYPES.capTable]: 'CapTables',
  default: 'MiscReporting',
}

export const REPORTING_DOCUMENT_TYPES_TARGET_FOLDER_PATH = {
  [REPORTING_DOCUMENT_TYPES.financials]: 'Financials/Actuals/In Progress',
  [REPORTING_DOCUMENT_TYPES.financialProjections]: 'Financials/Projections/In Progress',
  [REPORTING_DOCUMENT_TYPES.salesBySKU]: 'SalesBySKU/In Progress',
  [REPORTING_DOCUMENT_TYPES.arGeneralLedger]: 'GeneralLedger/In Progress',
  [REPORTING_DOCUMENT_TYPES.bankTransactions]: 'BankStatements/In Progress',
  [REPORTING_DOCUMENT_TYPES.capTable]: 'CapTables/In Progress',
  default: 'MiscReporting',
}

export const REPORTING_DOCUMENTS_TITLES = {
  [REPORTING_DOCUMENT_TYPES.apAging]: 'Payables Aging',
  [REPORTING_DOCUMENT_TYPES.arAging]: 'Receivables Aging',
  [REPORTING_DOCUMENT_TYPES.inventoryDetail]: 'Inventory Detail',
  [REPORTING_DOCUMENT_TYPES.arGeneralLedger]: 'AR General Ledger',
  [REPORTING_DOCUMENT_TYPES.salesBySKU]: 'Sales By SKU',
  [REPORTING_DOCUMENT_TYPES.financials]: 'Financials',
  [REPORTING_DOCUMENT_TYPES.financialProjections]: 'Financial Projections',
  [REPORTING_DOCUMENT_TYPES.capTable]: 'Cap Table',
  [REPORTING_DOCUMENT_TYPES.bankTransactions]: 'Bank Transactions',
}

export const REPORTING_DOCUMENT_FREQUENCY = {
  [REPORTING_DOCUMENT_TYPES.apAging]: [FREQUENCY_TYPES.atFunding, FREQUENCY_TYPES.monthly],
  [REPORTING_DOCUMENT_TYPES.arAging]: [FREQUENCY_TYPES.atFunding, FREQUENCY_TYPES.monthly],
  [REPORTING_DOCUMENT_TYPES.inventoryDetail]: [FREQUENCY_TYPES.atFunding, FREQUENCY_TYPES.monthly],
  [REPORTING_DOCUMENT_TYPES.arGeneralLedger]: [FREQUENCY_TYPES.quarterly],
  [REPORTING_DOCUMENT_TYPES.salesBySKU]: [FREQUENCY_TYPES.quarterly],
  [REPORTING_DOCUMENT_TYPES.financials]: [FREQUENCY_TYPES.monthly],
  [REPORTING_DOCUMENT_TYPES.financialProjections]: [FREQUENCY_TYPES.semiAnnually],
  [REPORTING_DOCUMENT_TYPES.capTable]: [FREQUENCY_TYPES.adHoc],
  [REPORTING_DOCUMENT_TYPES.bankTransactions]: [FREQUENCY_TYPES.monthly],
}

export const AGGREGATION_TYPES = {
  check: 'Collection - Check',
  wire: 'Collection - Wire',
  adjustment: 'Adjustment',
  fee: 'Fee',
  wireFee: 'Wire Fee',
  facilityFee: 'Facility Fee',
  lockboxFee: 'Lockbox Fee',
  terminationFee: 'Termination Fee',
  overadvancesFee: 'Overadvance Fee',
  funding: 'Funding',
  passThroughs: 'Pass-Through',
  interestAmountMonthly: 'Interest Fee',
  unusedLineFee: 'Unused Line Fee',
  principal: 'Principal',
}

export enum ClientAggregationTypes {
  FeesAdjustments = 'Fees & Adjustments',
  Collections = 'Collections',
  Funding = 'Funding',
}

export const CLIENT_AGGREGATION_TYPES = [
  {
    value: ClientAggregationTypes.FeesAdjustments,
    label: ClientAggregationTypes.FeesAdjustments,
  },
  { value: ClientAggregationTypes.Collections, label: ClientAggregationTypes.Collections },
  { value: ClientAggregationTypes.Funding, label: ClientAggregationTypes.Funding },
]
export const CLIENT_AGGREGATION_TYPE_MAPPING = {
  [ClientAggregationTypes.FeesAdjustments]: [
    AGGREGATION_TYPES.fee,
    AGGREGATION_TYPES.wireFee,
    AGGREGATION_TYPES.facilityFee,
    AGGREGATION_TYPES.lockboxFee,
    AGGREGATION_TYPES.terminationFee,
    AGGREGATION_TYPES.overadvancesFee,
    AGGREGATION_TYPES.adjustment,
    AGGREGATION_TYPES.interestAmountMonthly,
    AGGREGATION_TYPES.principal,
    AGGREGATION_TYPES.unusedLineFee,
  ],
  [ClientAggregationTypes.Collections]: [AGGREGATION_TYPES.check, AGGREGATION_TYPES.wire],
  [ClientAggregationTypes.Funding]: [AGGREGATION_TYPES.funding, AGGREGATION_TYPES.passThroughs],
}

export const CLIENT_INTAKE_STEPS = {
  GET_STARTED: 0,
  COMPANY_INFO: 1,
  PEOPLE: 2,
  DOCUMENTATION: 3,
}

export const CLIENT_INTAKE_STEPS_LIST = Object.values(CLIENT_INTAKE_STEPS).filter(
  (step) => step !== CLIENT_INTAKE_STEPS.GET_STARTED,
)

export const CLIENT_INTAKE_HEADINGS = {
  [CLIENT_INTAKE_STEPS.GET_STARTED]: {
    title: 'Let’s get started',
    description: 'Fill out this brief form so we can find the best solution for your capital needs',
  },
  [CLIENT_INTAKE_STEPS.COMPANY_INFO]: {
    title: 'Company info',
    description: 'Let’s hear a bit about your business',
  },
  [CLIENT_INTAKE_STEPS.PEOPLE]: {
    title: 'People & tools',
    description: 'Tell us about the people and systems behind your business',
  },
  [CLIENT_INTAKE_STEPS.DOCUMENTATION]: {
    title: 'Documentation',
    description: 'Please upload docs below in Excel format',
  },
}

export const CLIENT_ENTITY_RELATIONSHIP_TYPE_SALESFORCE_DESCRIPTION = {
  Prospect: {
    [ClientEntityRelationshipType.LawFirm]: 'Added from Client Intake: Law Firm',
    [ClientEntityRelationshipType.OutsourcedCFO]: 'Added from Client Intake: Outsourced CFO',
    [ClientEntityRelationshipType.AccountingFirm]: 'Added from Client Intake: Accounting firm',
    [ClientEntityRelationshipType.DistributionServices3PL]: 'Added from Client Intake: 3PL',
  },
  'Due Diligence': {
    [ClientEntityRelationshipType.LawFirm]: 'Added from Underwriting application: Law Firm',
    [ClientEntityRelationshipType.OutsourcedCFO]:
      'Added from Underwriting application: Outsourced CFO',
    [ClientEntityRelationshipType.AccountingFirm]:
      'Added from Underwriting application: Accounting firm',
    [ClientEntityRelationshipType.DistributionServices3PL]:
      'Added from Underwriting application: 3PL',
  },
}

export const LAST_NOTIFICATIONS_COUNT = 5

export enum CollateralTypes {
  ar = 'AR',
  ap = 'AP',
  inventory = 'Inventory',
}

export const OVERPAYABLE_INTERVAL_VALUES = [
  {
    value: '0',
    label: 'All',
  },
  {
    value: '30',
    label: '30',
  },
  {
    value: '60',
    label: '60',
  },
  {
    value: '90',
    label: '90',
  },
]

export const VENDOR_TYPE_VALUES = [
  { value: 'BNPL', label: 'BNPL' },
  { value: 'Payment Processor', label: 'Payment Processor' },
]

export enum InsuranceTypes {
  EOP = 'EOP',
  COI = 'COI',
}

export const MONTHS_OF_SALES = 12

export const CLIENT_CASH_FLOW_FIELDS = [
  'beginingCash',
  'avgNetIncome',
  'operatingActivities',
  'changeInAccountsReceivableNet',
  'changeInInventory',
  'changeInPrepaidExpenses',
  'changeInAccountsPayable',
  'changeInAccruedExpenses',
  'investingActivities',
  'changeInNetPpe',
  'financingActivities',
  'changeInAdditionalPaidInCapital',
  'changeInConvertibleNotesEquity',
  'changeInDwightFundingLoan',
  'changeInOther',
  'changeInCash',
  'endingCash',
  'avgOperatingCashFlow',
  'monthsOfCashOnHandOperatingCashFlow',
  'avgEBITDA',
  'monthsOfCashOnHandEBITDA',
]

export const CLIENT_CASH_FLOW_HIGHLIGHTED_FIELDS = [
  'avgOperatingCashFlow',
  'avgEBITDA',
  'monthsOfCashOnHandOperatingCashFlow',
  'monthsOfCashOnHandEBITDA',
]

export const CLIENT_CASH_FLOW_NUMBER_FIELDS = [
  'monthsOfCashOnHandOperatingCashFlow',
  'monthsOfCashOnHandEBITDA',
]

export const CLIENT_CASH_FLOW_HEADER_FIELDS = [
  'operatingActivities',
  'investingActivities',
  'financingActivities',
]

export const CLIENT_CASH_FLOW_INDENT_FIELDS = [
  'changeInAccountsReceivableNet',
  'changeInInventory',
  'changeInPrepaidExpenses',
  'changeInAccountsPayable',
  'changeInAccruedExpenses',
  'changeInNetPpe',
  'changeInAdditionalPaidInCapital',
  'changeInConvertibleNotesEquity',
  'changeInDwightFundingLoan',
]

export const CLIENT_CASH_FLOW_GRAPH_FIELDS = [
  'avgOperatingCashFlow',
  'monthsOfCashOnHandOperatingCashFlow',
  'avgEBITDA',
  'monthsOfCashOnHandEBITDA',
]

export const CLIENT_CASH_FLOW_GRAPH_FIELDS_COLORS = {
  avgOperatingCashFlow: '#395580',
  monthsOfCashOnHandOperatingCashFlow: '#718096',
  avgEBITDA: '#CBD5E0',
  monthsOfCashOnHandEBITDA: '#0066f5',
}

export const CLIENT_CASH_FLOW_GRAPH_LINE_FIELDS = [
  'monthsOfCashOnHandOperatingCashFlow',
  'monthsOfCashOnHandEBITDA',
]

export const CLIENT_CASH_FLOW_FIELDS_LABELS = {
  beginingCash: 'Starting Cash',
  avgNetIncome: 'Net Income',
  operatingActivities: 'Operating Activities',
  changeInAccountsReceivableNet: 'Accounts Receivable',
  changeInInventory: 'Inventory',
  changeInPrepaidExpenses: 'Prepaid Expenses',
  changeInAccountsPayable: 'Accounts Payable',
  changeInAccruedExpenses: 'Accrued Expenses',
  investingActivities: 'Investing Activities',
  changeInNetPpe: 'Net PPE',
  financingActivities: 'Financing Activities',
  changeInAdditionalPaidInCapital: 'Additional Paid in Capital',
  changeInConvertibleNotesEquity: 'Convertible Notes',
  changeInDwightFundingLoan: 'Dwight Funding Loan',
  changeInOther: 'Change in Other',
  changeInCash: 'Net Change in Cash',
  endingCash: 'Ending Cash',
  avgOperatingCashFlow: 'Operating Cash Flow',
  monthsOfCashOnHandOperatingCashFlow: 'Months of Cash On-hand (Operating Cash Flow)',
  avgEBITDA: 'EBITDA',
  monthsOfCashOnHandEBITDA: 'Months of Cash On-hand (EBITDA)',
}

export const CLIENT_AR_ROLLFORWARD_GRAPH_FIELDS = [
  'newInvoices',
  'actualCollections',
  'impliedCollections',
  'impliedDilution',
]

export const CLIENT_AR_ROLLFORWARD_FIELDS_LABELS = {
  newInvoices: 'New Invoices',
  actualCollections: 'Actual Collections',
  impliedCollections: 'Implied Collections',
  impliedDilution: 'Implied Dilution',
}

export const CLIENT_AR_ROLLFORWARD_GRAPH_FIELDS_COLORS = {
  newInvoices: '#718096',
  actualCollections: '#395580',
  impliedCollections: '#CBD5E0',
  impliedDilution: '#0066f5',
}

export const CLIENT_AR_ROLLFORWARD_GRAPH_LINE_FIELDS = ['impliedDilution']

export enum LCRCommentarySections {
  CompanyBackground = 'Company Background',
  TransactionOverview = 'Transaction Overview',
  MiscelleanousInfo = 'Miscelleanous Info',
  LegalConsiderations = 'Legal Considerations',
  ItemsToMonitor = 'Items to Monitor',
  HistoricalLineTurn = 'Historical Line Turn',
  HistoricalPL = 'Historical P&L',
  BalanceSheet = 'Balance Sheet',
  ForecastPL = 'Forecast P&L',
  StressCase = 'Stress Case',
  DilutionAnalysis = 'Dilution Analysis',
  CollateralAnalysisAR = 'Collateral Analysis - AR',
  CollateralAnalysisAP = 'Collateral Analysis - AP',
  CollateralAnalysisInventory = 'Collateral Analysis - Inventory',
  CollateralAnalysisSales = 'Collateral Analysis - Sales',
  InventoryAppraisal = 'Inventory Appraisal',
  CapitalizationStructure = 'Capitalization Structure',
  FieldExamReceivables = 'Field Exam - AR',
  FieldExamPayables = 'Field Exam - AP',
  FieldExamInventory = 'Field Exam - Inventory',
  FieldExamMiscellaneous = 'Field Exam - Miscellaneous',
}

export const LCR_COMMENTARY_DEFAULT_CUSTOM_HEADER = {
  [LCRCommentarySections.CollateralAnalysisAR]: 'Collateral Analysis',
  [LCRCommentarySections.CollateralAnalysisAP]: 'Collateral Analysis',
  [LCRCommentarySections.CollateralAnalysisInventory]: 'Collateral Analysis',
  [LCRCommentarySections.CollateralAnalysisSales]: 'Collateral Analysis',
  [LCRCommentarySections.FieldExamReceivables]: 'Receivables',
  [LCRCommentarySections.FieldExamPayables]: 'Payables',
  [LCRCommentarySections.FieldExamInventory]: 'Inventory',
  [LCRCommentarySections.FieldExamMiscellaneous]: 'Miscellaneous',
}

export const LCR_COMMENTARY_DEFAULT: {
  field: string
  section: string
  helperText: string | null
  defaultText: string | null
}[] = [
  {
    field: 'Company Background',
    section: LCRCommentarySections.CompanyBackground,
    helperText: null,
    defaultText: null,
  },
  {
    field: 'Cash Burn Coverage',
    section: LCRCommentarySections.TransactionOverview,
    helperText:
      'Provide brief commentary on cash burn coverage under different scenarios, or note if profitable. If applicable, discuss how additional availability from Dwight or other pending cash infusions can impact coverage.',
    defaultText: `As of xxx xxxx, the Company has $xxx in cash on hand. This would provide coverage as follows:

• Historical:
• Base Case: 
• Stress Case: 


Dwight Availability and Other Cash Infusions`,
  },
  {
    field: 'Financials - Investor Support & Investment Timeline',
    section: LCRCommentarySections.TransactionOverview,
    helperText:
      'Provide brief commentary or bullet points within each subsection. Follow the recommended format for the "Summary of Prior Rounds" subsection.',
    defaultText: `Summary of Prior Rounds

[Round - Date]
Amount Invested:
Key Investors:
Valuation:



Future Capital Raise Plans & Underwriting's Assessment`,
  },
  {
    field: 'Collateral Performance - AR',
    section: LCRCommentarySections.TransactionOverview,
    helperText: 'Provide brief commentary after each bullet point.',
    defaultText: `• Customer Base:
• AR Agings:
• AR Dilution:
• Other Commentary:`,
  },
  {
    field: 'Collateral Performance - Inventory',
    section: LCRCommentarySections.TransactionOverview,
    helperText: null,
    defaultText: `• Inventory Description:
• Weeks of Supply:
• Agings:
• Other Commentary:`,
  },
  {
    field: 'Founders / Management Team',
    section: LCRCommentarySections.TransactionOverview,
    helperText: null,
    defaultText: `{executives}



Underwriting's Assessment`,
  },
  {
    field: 'Key Competitors',
    section: LCRCommentarySections.MiscelleanousInfo,
    helperText: null,
    defaultText: null,
  },
  {
    field: 'Marketing Strategy',
    section: LCRCommentarySections.MiscelleanousInfo,
    helperText: null,
    defaultText: null,
  },
  {
    field: 'Lawsuits / Negative Press',
    section: LCRCommentarySections.MiscelleanousInfo,
    helperText: null,
    defaultText: null,
  },
  {
    field: 'Warehouse / Landlord Waivers',
    section: LCRCommentarySections.LegalConsiderations,
    helperText: null,
    defaultText: `Inventory Locations



Warehouse / Landlord Waivers (Eligible Locations)`,
  },
  {
    field: 'Subordination Agreements',
    section: LCRCommentarySections.LegalConsiderations,
    helperText: null,
    defaultText: null,
  },
  {
    field: 'Co-Borrowers & Corporate Guaranties',
    section: LCRCommentarySections.LegalConsiderations,
    helperText: null,
    defaultText: null,
  },
  {
    field: 'Validity & Support Guaranties',
    section: LCRCommentarySections.LegalConsiderations,
    helperText: null,
    defaultText: null,
  },
  {
    field: 'Debt Payoffs & Lien Terminations',
    section: LCRCommentarySections.LegalConsiderations,
    helperText: null,
    defaultText: `Debt Payoffs



Lien Terminations`,
  },
  {
    field: 'Initial Reserves Summary',
    section: LCRCommentarySections.ItemsToMonitor,
    helperText: null,
    defaultText: `Starting Reserves Summary



Items to Monitor Summary`,
  },
  {
    field: 'Stepped Up Reserves Summary',
    section: LCRCommentarySections.ItemsToMonitor,
    helperText: null,
    defaultText: `Condition / Timing



Stepped Up Reserves`,
  },
  {
    field: 'AR & Financials',
    section: LCRCommentarySections.ItemsToMonitor,
    helperText: null,
    defaultText: `Condition / Timing



Reserve / Other Recommendation



Rationale / Other Commentary`,
  },
  {
    field: 'Inventory & Other',
    section: LCRCommentarySections.ItemsToMonitor,
    helperText: null,
    defaultText: `Condition / Timing



Reserve / Other Recommendation



Rationale / Other Commentary`,
  },
  {
    field: 'Historical Line Turn',
    section: LCRCommentarySections.HistoricalLineTurn,
    helperText: null,
    defaultText: `Line Turn Anomalies



Calculation Assumptions`,
  },
  {
    field: 'Sales Trends',
    section: LCRCommentarySections.HistoricalPL,
    helperText: null,
    defaultText: null,
  },
  {
    field: 'Sales Anomalies',
    section: LCRCommentarySections.HistoricalPL,
    helperText: null,
    defaultText: null,
  },
  {
    field: 'P&L Dilution Trends',
    section: LCRCommentarySections.HistoricalPL,
    helperText: null,
    defaultText: null,
  },
  {
    field: 'P&L Dilution Anomalies',
    section: LCRCommentarySections.HistoricalPL,
    helperText: null,
    defaultText: null,
  },
  {
    field: 'Gross Margin Trends',
    section: LCRCommentarySections.HistoricalPL,
    helperText: null,
    defaultText: null,
  },
  {
    field: 'Gross Margin Anomalies',
    section: LCRCommentarySections.HistoricalPL,
    helperText: null,
    defaultText: null,
  },
  {
    field: 'Operating Expense Trends & Anomalies',
    section: LCRCommentarySections.HistoricalPL,
    helperText: null,
    defaultText: null,
  },
  {
    field: 'Balance Sheet Trends & Anomalies',
    section: LCRCommentarySections.BalanceSheet,
    helperText: null,
    defaultText: null,
  },
  {
    field: 'Notable Balance Sheet Items',
    section: LCRCommentarySections.BalanceSheet,
    helperText: null,
    defaultText: null,
  },
  {
    field: 'Base Case Forecast',
    section: LCRCommentarySections.ForecastPL,
    helperText: null,
    defaultText: `• Base Case Revenue:
• Base Case Dilution:
• Base Case Gross Margins:
• Base Case EBITDA:



Base Case Drivers



Base Case Cash Burn Coverage`,
  },
  {
    field: 'Stress Case Forecast',
    section: LCRCommentarySections.StressCase,
    helperText: null,
    defaultText: `• Stress Case Revenue:
• Stress Case Dilution:
• Stress Case Gross Margins:
• Stress Case EBITDA:



Stress Case Assumptions



Stress Case Cash Burn Coverage`,
  },
  {
    field: 'Dilution Calculation and Drivers',
    section: LCRCommentarySections.DilutionAnalysis,
    helperText: null,
    defaultText: `"• Underwriting's Calculation:
• Field Examiner's Calculation:



Dilution Drivers and Commentary`,
  },
  {
    field: 'Dilution Anomalies',
    section: LCRCommentarySections.DilutionAnalysis,
    helperText: null,
    defaultText: null,
  },
  {
    field: 'AR Trends Commentary',
    section: LCRCommentarySections.CollateralAnalysisAR,
    helperText: null,
    defaultText: null,
  },
  {
    field: 'AR Anomalies & Aged AR Commentary',
    section: LCRCommentarySections.CollateralAnalysisAR,
    helperText: null,
    defaultText: null,
  },
  {
    field: 'Top 5 Customers',
    section: LCRCommentarySections.CollateralAnalysisAR,
    helperText: null,
    defaultText: null,
  },
  {
    field: 'Top 5 Customer Anomalies',
    section: LCRCommentarySections.CollateralAnalysisAR,
    helperText: null,
    defaultText: null,
  },
  {
    field: 'AP Trends Commentary',
    section: LCRCommentarySections.CollateralAnalysisAP,
    helperText: null,
    defaultText: null,
  },
  {
    field: 'AP Anomalies & Aged AP Commentary',
    section: LCRCommentarySections.CollateralAnalysisAP,
    helperText: null,
    defaultText: null,
  },
  {
    field: 'Top 5 Vendors',
    section: LCRCommentarySections.CollateralAnalysisAP,
    helperText: null,
    defaultText: null,
  },
  {
    field: 'Top 5 Vendor Anomalies & Contras',
    section: LCRCommentarySections.CollateralAnalysisAP,
    helperText: null,
    defaultText: null,
  },
  {
    field: 'Weeks of Supply',
    section: LCRCommentarySections.CollateralAnalysisSales,
    helperText: null,
    defaultText: null,
  },
  {
    field: 'Aging Distribution',
    section: LCRCommentarySections.CollateralAnalysisInventory,
    helperText: null,
    defaultText: null,
  },
  {
    field: 'Sizing Distribution',
    section: LCRCommentarySections.CollateralAnalysisInventory,
    helperText: null,
    defaultText: null,
  },
  {
    field: 'Liquidation Channels',
    section: LCRCommentarySections.InventoryAppraisal,
    helperText: null,
    defaultText: `Appraiser's Liquidation Strategy




Underwriting's Assessment`,
  },
  {
    field: 'Lender Risks and Collateral Monitoring',
    section: LCRCommentarySections.InventoryAppraisal,
    helperText: null,
    defaultText: null,
  },
  {
    field: 'Investor & Shareholder Background',
    section: LCRCommentarySections.CapitalizationStructure,
    helperText: null,
    defaultText: null,
  },
  {
    field: 'Equity History',
    section: LCRCommentarySections.CapitalizationStructure,
    helperText: null,
    defaultText: `Summary of Prior Rounds (Including Key Investors, Amounts Invested, and Valuations)

[Round - Date]
Amount Invested:
Key Investors:
Valuation:




Secondary Sales and Preference Stack




Underwriting's Valuation Assessment`,
  },
  {
    field: 'Future Equity Raises',
    section: LCRCommentarySections.CapitalizationStructure,
    helperText: null,
    defaultText: null,
  },
  {
    field: 'Debt History',
    section: LCRCommentarySections.CapitalizationStructure,
    helperText: null,
    defaultText: null,
  },
  {
    field: 'Future Debt Raises',
    section: LCRCommentarySections.CapitalizationStructure,
    helperText: null,
    defaultText: null,
  },
  {
    field: 'AR Verification Test',
    section: LCRCommentarySections.FieldExamReceivables,
    helperText: null,
    defaultText: null,
  },
  {
    field: 'AR Billing / Shipping Test',
    section: LCRCommentarySections.FieldExamReceivables,
    helperText: null,
    defaultText: null,
  },
  {
    field: 'Credit Memo Test',
    section: LCRCommentarySections.FieldExamReceivables,
    helperText: null,
    defaultText: null,
  },
  {
    field: 'Past Due Test',
    section: LCRCommentarySections.FieldExamReceivables,
    helperText: null,
    defaultText: null,
  },
  {
    field: 'Cash Application Test',
    section: LCRCommentarySections.FieldExamReceivables,
    helperText: null,
    defaultText: null,
  },
  {
    field: 'Cash Disbursement Test',
    section: LCRCommentarySections.FieldExamPayables,
    helperText: null,
    defaultText: null,
  },
  {
    field: 'Inventory Test Count',
    section: LCRCommentarySections.FieldExamInventory,
    helperText: null,
    defaultText: null,
  },
  {
    field: 'Inventory Cost Test',
    section: LCRCommentarySections.FieldExamInventory,
    helperText: null,
    defaultText: null,
  },
  {
    field: 'Inventory Gross Profit Test',
    section: LCRCommentarySections.FieldExamInventory,
    helperText: null,
    defaultText: null,
  },
  {
    field: 'Bank Reconciliation',
    section: LCRCommentarySections.FieldExamMiscellaneous,
    helperText: null,
    defaultText: null,
  },
  {
    field: 'Payroll & Sales Tax Review',
    section: LCRCommentarySections.FieldExamMiscellaneous,
    helperText: null,
    defaultText: null,
  },
  {
    field: 'Insurance Review',
    section: LCRCommentarySections.FieldExamMiscellaneous,
    helperText: 'Provide brief commentary on any issues.',
    defaultText: null,
  },
]
