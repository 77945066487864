import { Box, Grid } from '@mui/material'
import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { generatePath, useParams } from 'react-router'
import LoanCommitteeReportHeader from '../../components/LoanCommitteeReportHeader'
import OngoingReportingSummary from '../../components/OngoingReportingSummary'
import {
  OngoingReportingType,
  ReportingFlow,
  ReportingPeriods,
  AnnuallyReportingPeriods,
  DisplayGrowthPeriods,
  FinancialsType,
} from '@common/interfaces/bbc'
import { DUE_DILIGENCE_ROUTES_MAP } from '@common/interfaces/dueDiligence'
import { ROUTES } from '../../constants/routes'
import StressCaseAssumptions from '../../components/StressCaseAssumptions'
import Modal from '../../components/Common/Modal'
import LCRCommentary from '../../components/LCRCommentary'
import { LCRCommentarySections } from '@common/constants/client'

interface IProps {
  financialsReports: {
    data?: { asOfDates: string[]; startDate: string; projectionsStartDate: string }
  }
  listFinancialsReports: (id: string) => void
  hideFinancialsReports: () => void
}

const LoanCommitteeReportForecastPage = ({
  financialsReports,
  listFinancialsReports,
  hideFinancialsReports,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const projectionsStartDate = useMemo(
    () => financialsReports.data?.projectionsStartDate || null,
    [financialsReports],
  )
  const asOfDates = useMemo(() => financialsReports.data?.asOfDates || [], [financialsReports])
  const [refreshCounter, setRefreshCounter] = useState(1)
  const handleRefreshCounter = useCallback(() => setRefreshCounter((prev) => prev + 1), [])
  const [stressCaseOpen, setStressCaseOpen] = useState(false)
  const handleOpenStressCase = useCallback(() => setStressCaseOpen(true), [])
  const handleCloseStressCase = useCallback(() => {
    setStressCaseOpen(false)
    handleRefreshCounter()
  }, [handleRefreshCounter])

  const [isISModalShown, setIsISModalShown] = useState(false)
  const [isStressCaseModalShown, setIsStressCaseModalShown] = useState(false)

  const [currentReportingPeriod, setCurrentReportingPeriod] = useState<ReportingPeriods>(
    ReportingPeriods.Monthly,
  )
  const [currentAnnuallyReportingPeriods, setCurrentAnnuallyReportingPeriods] = useState<
    AnnuallyReportingPeriods[]
  >([AnnuallyReportingPeriods.FY, AnnuallyReportingPeriods.YTD])
  const [currentDisplayGrowth, setCurrentDisplayGrowth] = useState<DisplayGrowthPeriods>(
    DisplayGrowthPeriods.Hide,
  )
  const [stressDisplayGrowth, setStressDisplayGrowth] = useState<DisplayGrowthPeriods>(
    DisplayGrowthPeriods.Hide,
  )
  const [currentFinancialsTypes, setCurrentFinancialsTypes] = useState<FinancialsType[]>([
    FinancialsType.Actuals,
    FinancialsType.Projections,
  ])

  const [stressCaseCurrentFinancialsTypes, setStressCaseCurrentFinancialsTypes] = useState<
    FinancialsType[]
  >([FinancialsType.Actuals, FinancialsType.Projections, FinancialsType.StressCase])

  const [dateRange, setDateRange] = useState({ startDate: projectionsStartDate, endDate: null })

  useEffect(() => {
    if (projectionsStartDate) {
      setDateRange((dateRange) => ({
        ...dateRange,
        startDate: projectionsStartDate,
      }))
    }
  }, [projectionsStartDate])

  useEffect(() => {
    if (id) {
      listFinancialsReports(id)
    }
  }, [id, listFinancialsReports])

  useEffect(() => {
    return () => {
      hideFinancialsReports()
    }
  }, [hideFinancialsReports])

  return (
    <Box py={1} pr={2}>
      <LoanCommitteeReportHeader />
      <Grid container pr={1} spacing={2} mt={1}>
        <Grid item xs={12}>
          <OngoingReportingSummary
            isModalShown={isISModalShown}
            setIsModalShown={setIsISModalShown}
            statementType={OngoingReportingType.IncomeStatement}
            reportingFlow={ReportingFlow.DueDiligence}
            asOfDates={asOfDates}
            currentReportingPeriod={currentReportingPeriod}
            currentAnnuallyReportingPeriods={currentAnnuallyReportingPeriods}
            currentDisplayGrowth={currentDisplayGrowth}
            currentFinancialsTypes={currentFinancialsTypes}
            currentDateRange={dateRange}
            setCurrentReportingPeriod={setCurrentReportingPeriod}
            setCurrentAnnuallyReportingPeriods={setCurrentAnnuallyReportingPeriods}
            setCurrentDisplayGrowth={setCurrentDisplayGrowth}
            setCurrentFinancialsTypes={setCurrentFinancialsTypes}
            setCurrentDateRange={setDateRange}
            title="Base Case Income Statement"
            cardWithBorder={false}
            isSummary
            editLink={generatePath(ROUTES.DUE_DILIGENCE_PROCESS_DOCUMENTS_LIST, {
              id,
              type: DUE_DILIGENCE_ROUTES_MAP[OngoingReportingType.IncomeStatementProjections],
            })}
          />
        </Grid>

        <Grid item xs={12}>
          <LCRCommentary section={LCRCommentarySections.ForecastPL} />
        </Grid>

        <Grid item xs={12}>
          <OngoingReportingSummary
            isModalShown={isStressCaseModalShown}
            setIsModalShown={setIsStressCaseModalShown}
            statementType={OngoingReportingType.IncomeStatement}
            reportingFlow={ReportingFlow.DueDiligence}
            asOfDates={asOfDates}
            currentReportingPeriod={currentReportingPeriod}
            currentAnnuallyReportingPeriods={currentAnnuallyReportingPeriods}
            currentDisplayGrowth={stressDisplayGrowth}
            currentFinancialsTypes={stressCaseCurrentFinancialsTypes}
            currentDateRange={dateRange}
            setCurrentReportingPeriod={setCurrentReportingPeriod}
            setCurrentAnnuallyReportingPeriods={setCurrentAnnuallyReportingPeriods}
            setCurrentDisplayGrowth={setStressDisplayGrowth}
            setCurrentFinancialsTypes={setStressCaseCurrentFinancialsTypes}
            setCurrentDateRange={setDateRange}
            refreshCounter={refreshCounter}
            title="Stress Case Income Statement"
            cardWithBorder={false}
            isSummary
            editLink={handleOpenStressCase}
            isStressCase
          />
          <Modal
            open={stressCaseOpen}
            onCancel={handleCloseStressCase}
            title="Stress Case Assumptions"
          >
            <StressCaseAssumptions reportingFlow={ReportingFlow.DueDiligence} />
          </Modal>
        </Grid>

        <Grid item xs={12}>
          <LCRCommentary section={LCRCommentarySections.StressCase} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default LoanCommitteeReportForecastPage
