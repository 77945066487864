import { Box, Grid } from '@mui/material'
import React, { useState, useEffect, useMemo } from 'react'
import { generatePath, useParams } from 'react-router'

import LoanCommitteeReportHeader from '../../components/LoanCommitteeReportHeader'
import OngoingReportingSummary from '../../components/OngoingReportingSummary'
import ClientCashFlow from '../../components/Client/ClientCashFlow'
import {
  OngoingReportingType,
  ReportingFlow,
  ReportingPeriods,
  AnnuallyReportingPeriods,
  DisplayGrowthPeriods,
  FinancialsType,
} from '@common/interfaces/bbc'
import { DUE_DILIGENCE_ROUTES_MAP } from '@common/interfaces/dueDiligence'
import { ROUTES } from '../../constants/routes'
import LCRCommentary from '../../components/LCRCommentary'
import { LCRCommentarySections } from '@common/constants/client'

interface IProps {
  financialsReports: { data?: { asOfDates: string[]; startDate: string } }
  listFinancialsReports: (id: string) => void
  hideFinancialsReports: () => void
}

const LoanCommitteeReportFinancialsPage = ({
  financialsReports,
  listFinancialsReports,
  hideFinancialsReports,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const startDate = useMemo(() => financialsReports.data?.startDate || null, [financialsReports])
  const asOfDates = useMemo(() => financialsReports.data?.asOfDates || [], [financialsReports])

  const [isISModalShown, setIsISModalShown] = useState(false)
  const [isBSModalShown, setIsBSModalShown] = useState(false)
  const [currentReportingPeriod, setCurrentReportingPeriod] = useState<ReportingPeriods>(
    ReportingPeriods.Monthly,
  )
  const [currentAnnuallyReportingPeriods, setCurrentAnnuallyReportingPeriods] = useState<
    AnnuallyReportingPeriods[]
  >([AnnuallyReportingPeriods.FY, AnnuallyReportingPeriods.YTD])
  const [currentDisplayGrowth, setCurrentDisplayGrowth] = useState<DisplayGrowthPeriods>(
    DisplayGrowthPeriods.Hide,
  )
  const [currentFinancialsTypes, setCurrentFinancialsTypes] = useState<FinancialsType[]>([
    FinancialsType.Actuals,
    FinancialsType.Projections,
  ])

  const [dateRange, setDateRange] = useState({ startDate, endDate: null })

  useEffect(() => {
    if (startDate) {
      setDateRange((dateRange) => ({
        ...dateRange,
        startDate,
      }))
    }
  }, [startDate])

  useEffect(() => {
    if (id) {
      listFinancialsReports(id)
    }
  }, [id, listFinancialsReports])

  useEffect(() => {
    return () => {
      hideFinancialsReports()
    }
  }, [hideFinancialsReports])

  return (
    <Box py={1} pr={2}>
      <LoanCommitteeReportHeader />
      <Grid container pr={1} spacing={2} mt={1}>
        <Grid item xs={12}>
          <OngoingReportingSummary
            isModalShown={isISModalShown}
            setIsModalShown={setIsISModalShown}
            statementType={OngoingReportingType.IncomeStatement}
            reportingFlow={ReportingFlow.DueDiligence}
            asOfDates={asOfDates}
            currentReportingPeriod={currentReportingPeriod}
            currentAnnuallyReportingPeriods={currentAnnuallyReportingPeriods}
            currentDisplayGrowth={currentDisplayGrowth}
            currentFinancialsTypes={currentFinancialsTypes}
            currentDateRange={dateRange}
            setCurrentReportingPeriod={setCurrentReportingPeriod}
            setCurrentAnnuallyReportingPeriods={setCurrentAnnuallyReportingPeriods}
            setCurrentDisplayGrowth={setCurrentDisplayGrowth}
            setCurrentFinancialsTypes={setCurrentFinancialsTypes}
            setCurrentDateRange={setDateRange}
            title="Income Statement"
            cardWithBorder={false}
            isSummary
            editLink={generatePath(ROUTES.DUE_DILIGENCE_PROCESS_DOCUMENTS_LIST, {
              id,
              type: DUE_DILIGENCE_ROUTES_MAP[OngoingReportingType.IncomeStatement],
            })}
          />
        </Grid>

        <Grid item xs={12}>
          <LCRCommentary section={LCRCommentarySections.HistoricalPL} />
        </Grid>

        <Grid item xs={12}>
          <OngoingReportingSummary
            isModalShown={isBSModalShown}
            setIsModalShown={setIsBSModalShown}
            statementType={OngoingReportingType.BalanceSheet}
            reportingFlow={ReportingFlow.DueDiligence}
            asOfDates={asOfDates}
            currentReportingPeriod={currentReportingPeriod}
            currentAnnuallyReportingPeriods={currentAnnuallyReportingPeriods}
            currentDisplayGrowth={currentDisplayGrowth}
            currentFinancialsTypes={currentFinancialsTypes}
            currentDateRange={dateRange}
            setCurrentReportingPeriod={setCurrentReportingPeriod}
            setCurrentAnnuallyReportingPeriods={setCurrentAnnuallyReportingPeriods}
            setCurrentDisplayGrowth={setCurrentDisplayGrowth}
            setCurrentFinancialsTypes={setCurrentFinancialsTypes}
            setCurrentDateRange={setDateRange}
            title="Balance Sheet"
            cardWithBorder={false}
            isSummary
            editLink={generatePath(ROUTES.DUE_DILIGENCE_PROCESS_DOCUMENTS_LIST, {
              id,
              type: DUE_DILIGENCE_ROUTES_MAP[OngoingReportingType.BalanceSheet],
            })}
          />
        </Grid>

        <Grid item xs={12}>
          <LCRCommentary section={LCRCommentarySections.BalanceSheet} />
        </Grid>

        <Grid item xs={12}>
          <ClientCashFlow
            reportingFlow={ReportingFlow.DueDiligence}
            currentOptions={currentFinancialsTypes}
            currentReportingPeriod={currentReportingPeriod}
            currentDateRange={dateRange}
            setCurrentReportingPeriod={setCurrentReportingPeriod}
            setCurrentDateRange={setDateRange}
            setCurrentOptions={setCurrentFinancialsTypes}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default LoanCommitteeReportFinancialsPage
