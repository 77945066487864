import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import LoanCommitteeReportFieldExamPage from './LoanCommitteeReportFieldExamPage'

import { dueDiligenceInfo } from '../../redux/dueDiligence/selectors'

const selectors = createStructuredSelector({
  dueDiligenceInfo,
})

const actions = {}

export default connect(selectors, actions)(LoanCommitteeReportFieldExamPage)
