export enum ClientPageTabNames {
  Overview = 'Overview',
  Risk = 'Risk',
  Financials = 'Financials',
  Receivables = 'Receivables',
  Payable = 'Payables',
  Inventory = 'Inventory',
  BankTransactions = 'Transactions',
  Company = 'Company',
}

export enum ClientPageTabSubNames {
  CapTable = 'Cap Table',
  Background = 'Background',
  TheTeam = 'The Team',
}

export enum ClientVisualizationsPageTabNames {
  Receivables = 'Receivables',
  Payable = 'Payables',
  Inventory = 'Inventory',
}

export enum ClientPageActivityOption {
  AccountActivity = 'Activity',
  LoanLedger = 'Daily Ledger',
}

export const CLIENT_PAGE_ACTIVITY_OPTIONS = [
  {
    value: ClientPageActivityOption.AccountActivity,
    label: ClientPageActivityOption.AccountActivity,
  },
  {
    value: ClientPageActivityOption.LoanLedger,
    label: ClientPageActivityOption.LoanLedger,
  },
]

const ROUTES = {
  HOMEPAGE: '/',
  LOGIN: '/login',
  SIGNUP: '/signup',
  CONFIRM_EMAIL: '/confirm-email/:hash',
  COMPANY: '/company',
  CLIENTS: '/clients',
  CLIENT_PAGE: '/clients/:id',
  CLIENT_PAGE_RISK_PROFILE: `/clients/:id/?tab=${ClientPageTabNames.Risk}`,
  CLIENT_SUBMIT_REPORTING_PAGE: '/clients/:id/submit-reporting',
  RESET_PASSWORD: '/reset-password/:hash',
  PASSWORD_RECOVERY: '/password-recovery',
  PASSWORD_SETUP: '/password-setup/:hash',
  CLIENT_SETUP_CREATE: '/client-setup',
  CLIENT_SETUP: '/client-setup/:id',
  CLIENT_SETUP_LOAN_STRUCTURE_ASSET_BASED: '/client-setup/:id/loan-structure-asset-based',
  CLIENT_SETUP_LOAN_STRUCTURE_TERM: '/client-setup/:id/loan-structure-term',
  CLIENT_SETUP_LOAN_STRUCTURE_OVERADVANCE: '/client-setup/:id/loan-structure-overadvance',
  CLIENT_SETUP_LOAN_STRUCTURE_PARTICIPANTS: '/client-setup/:id/loan-structure-participants',
  CLIENT_SETUP_COLLATERAL_RECEIVABLE: '/client-setup/:id/collateral-receivable',
  CLIENT_SETUP_COLLATERAL_PAYABLE: '/client-setup/:id/collateral-payable',
  CLIENT_SETUP_COLLATERAL_INVENTORY: '/client-setup/:id/collateral-inventory',
  CLIENT_SETUP_ITEMS_TO_MONITOR: '/client-setup/:id/items-to-monitor',
  CLIENT_SETUP_REPORTING: '/client-setup/:id/reporting',
  CLIENT_SETUP_BANK_ACCOUNTS: '/client-setup/:id/bank-accounts',
  CLIENT_SETUP_USERS: '/client-setup/:id/users',
  CLIENT_SETUP_RELATIONSHIP_MANAGEMENT: '/client-setup/:id/relationship-management',
  CLIENT_SETUP_TERMINATION: '/client-setup/:id/termination',
  CLIENT_SETUP_GENERAL: '/client-setup/:id/general',
  CLIENT_INTEGRATIONS: '/client-integrations/:id',
  CLIENT_INTAKE: '/client-intake/:salesforceId',
  SETTINGS: '/settings',
  SETTINGS_PROFILE: '/settings/profile',
  SETTINGS_SECURITY: '/settings/security',
  CLIENT_SETTINGS_INTEGRATIONS: '/settings/integrations',
  CLIENT_SETTINGS_NOTIFICATIONS: '/settings/notifications',
  CLIENT_MANAGEMENT: '/client-management',
  CLIENT_REPORTING: '/client-management?tab=Reporting',
  USER_MANAGEMENT: '/user-management',
  AUDIT: '/audit-log',
  ACCOUNT_ACTIVITY: '/account-activity',
  SUBMIT_REPORTING: '/submit-reporting',
  ACTIVITY_QUEUE: '/operations',
  ANALYSIS_QUEUE: '/operations?tab=Reporting',
  OUTGOING_WIRE: '/outgoing-wire',
  COLLECTIONS: '/collections',
  COLLECTIONS_DASHBOARD: '/collections-dashboard',
  COLLECTIONS_DASHBOARD_OVERALL: '/collections-dashboard/overall',
  COLLECTIONS_DASHBOARD_NON_CLIENT_RELATED_CASH: '/collections-dashboard/non-client-related-cash',
  COLLECTIONS_WIRES: '/collections/wires',
  COLLECTIONS_MID_DAY_WIRES: '/collections/mid-day-wires',
  COLLECTIONS_CHECKS: '/collections/checks',
  ENTITY_MANAGEMENT: '/entities',
  ENTITY_SETTINGS: '/entity-settings/:id',
  ENTITY_SETTINGS_GENERAL: '/entity-settings/:id/general',
  ENTITY_SETTINGS_RECEIVABLES: '/entity-settings/:id/receivables',
  ENTITY_SETTINGS_PAYABLES: '/entity-settings/:id/payables',
  ENTITY_SETTINGS_ALIAS_MAPPING: '/entity-settings/:id/aliases',
  ENTITY_SETTINGS_RELATED_ENTITIES: '/entity-settings/:id/related-entities',

  BBC: '/bbc/:id',
  BBC_INVENTORY: '/bbc/:id/inventory',
  BBC_COLLATERAL_ELIGIBILITY: '/bbc/:id/collateral-eligibility',
  BBC_COLLATERAL_ELIGIBILITY_RECEIVABLES: '/bbc/:id/collateral-eligibility/receivables',
  BBC_COLLATERAL_ELIGIBILITY_INVENTORY: '/bbc/:id/collateral-eligibility/inventory',
  BBC_AR_INELIGIBILITY: '/bbc/:id/ar-ineligibility',
  BBC_INVENTORY_INELIGIBLE: '/bbc/:id/inventory-ineligible',
  BBC_INVENTORY_INELIGIBLE_ANALYSIS: '/bbc/:id/inventory-ineligible-analysis',
  BBC_ANALYSIS: '/bbc/:id/analysis',
  BBC_SUMMARY: '/bbc/:id/summary',
  BBC_SUMMARY_RECEIVABLES: '/bbc/:id/summary/receivables',
  BBC_SUMMARY_PAYABLES: '/bbc/:id/summary/payables',
  BBC_SUMMARY_INVENTORY: '/bbc/:id/summary/inventory',

  ONGOING_REPORTING: '/ongoing-reporting/:id',
  ONGOING_REPORTING_INCOME_STATEMENT: '/ongoing-reporting/:id/income-statement',
  ONGOING_REPORTING_BALANCE_SHEET: '/ongoing-reporting/:id/balance-sheet',
  ONGOING_REPORTING_SUMMARY: '/ongoing-reporting/:id/summary',
  ONGOING_REPORTING_SALES_BY_SKU: '/ongoing-reporting/sales-by-sku/:id',
  ONGOING_REPORTING_AR_GENERAL_LEDGER: '/ongoing-reporting/ar-general-ledger/:id',

  BANK_TRANSACTIONS_UPLOAD: '/ongoing-reporting/:id/bank-transactions',
  CAP_TABLE_UPLOAD: '/cap-table/:id',

  PROSPECTS: '/prospects-list',
  PROSPECT_SETUP: '/prospects',
  PROSPECT_PAGE: '/prospects/:id',
  PROSPECT_APPLICATION_PAGE: '/prospects/:id/application',
  PROSPECT_ANALYSIS_QUEUE: '/prospects/:id/analysis-queue',
  PROSPECT_SUMMARY_PAGE: '/prospects/:id/summary',
  PROSPECT_REPORTING_RECEIVABLES: '/prospects/:id/receivables',
  PROSPECT_REPORTING_PAYABLES: '/prospects/:id/payables',
  PROSPECT_REPORTING_INVENTORY: '/prospects/:id/inventory',
  PROSPECT_REPORTING_INCOME_STATEMENT: '/prospects/:id/income-statement',
  PROSPECT_REPORTING_BALANCE_SHEET: '/prospects/:id/balance-sheet',
  PROSPECT_REPORTING_INCOME_STATEMENT_PROJECTIONS: '/prospects/:id/income-statement-projections',
  PROSPECT_REPORTING_BALANCE_SHEET_PROJECTIONS: '/prospects/:id/balance-sheet-projections',
  PROSPECT_MANAGE_TERMS_PAGE: '/prospects/:id/manage-terms',
  PROSPECT_ADD_TERMS_PAGE: '/prospects/:id/manage-terms/add',
  PROSPECT_EDIT_TERMS_PAGE: '/prospects/:id/manage-terms/edit/:termId',

  DUE_DILIGENCE_HOME: '/due-diligence-home',
  DUE_DILIGENCE: '/due-diligence/:id',
  DUE_DILIGENCE_TEAM_PAGE: '/due-diligence/:id/team',
  DUE_DILIGENCE_CLIENT_FINANCIALS_PAGE: '/due-diligence/:id/client-financials',
  DUE_DILIGENCE_ANALYSIS_QUEUE: '/due-diligence/:id/analysis-queue',
  DUE_DILIGENCE_PROCESS_DOCUMENTS_LIST: '/due-diligence/:id/process-documents/:type',
  DUE_DILIGENCE_PROCESS_DOCUMENTS_MAPPING: '/due-diligence/:id/process-documents/:type/mapping',
  DUE_DILIGENCE_PROCESS_DOCUMENTS_FINANCIALS:
    '/due-diligence/:clientId/process-documents/:type/financials/:id',
  DUE_DILIGENCE_PROCESS_DOCUMENTS_BANK_TRANSACTIONS:
    '/due-diligence/:id/process-documents/:type/transaction-mapping',
  DUE_DILIGENCE_PROCESS_DOCUMENTS_GENERAL_LEDGER:
    '/due-diligence/:id/process-documents/:type/ar-general-ledger-mapping',
  DUE_DILIGENCE_PROCESS_DOCUMENTS_INVENTORY:
    '/due-diligence/:id/process-documents/:type/inventory-mapping',
  DUE_DILIGENCE_PROCESS_DOCUMENTS_SALES_BY_SKU:
    '/due-diligence/:id/process-documents/:type/sales-by-sku-mapping',
  DUE_DILIGENCE_PROCESS_DOCUMENTS_CAP_TABLE:
    '/due-diligence/:id/process-documents/:type/cap-table-mapping',
  DUE_DILIGENCE_VALIDATE_DOCUMENTS_PAGE: '/due-diligence/:id/validate-documents',

  DUE_DILIGENCE_LOAN_COMMITTEE_REPORT_PAGE: '/due-diligence/:id/loan-committee-report',
  DUE_DILIGENCE_LOAN_COMMITTEE_REPORT_FINANCIALS_PAGE:
    '/due-diligence/:id/loan-committee-report/financials',
  DUE_DILIGENCE_LOAN_COMMITTEE_REPORT_FORECAST_PAGE:
    '/due-diligence/:id/loan-committee-report/forecasts',
  DUE_DILIGENCE_LOAN_COMMITTEE_REPORT_TRANSACTIONS_PAGE:
    '/due-diligence/:id/loan-committee-report/transactions',
  DUE_DILIGENCE_LOAN_COMMITTEE_REPORT_RECEIVABLES_PAGE:
    '/due-diligence/:id/loan-committee-report/receivables',
  DUE_DILIGENCE_LOAN_COMMITTEE_REPORT_PAYABLES_PAGE:
    '/due-diligence/:id/loan-committee-report/payables',
  DUE_DILIGENCE_LOAN_COMMITTEE_REPORT_INVENTORY_PAGE:
    '/due-diligence/:id/loan-committee-report/inventory',
  DUE_DILIGENCE_LOAN_COMMITTEE_REPORT_CAP_TABLE_PAGE:
    '/due-diligence/:id/loan-committee-report/cap-table',
  DUE_DILIGENCE_LOAN_COMMITTEE_REPORT_FIELD_EXAM_PAGE:
    '/due-diligence/:id/loan-committee-report/field-exam',

  ENTITY_PAGE: '/entity/:id',

  PARTICIPANTS_MANAGEMENT: '/participants',
  PARTICIPANTS_PAGE: '/participants/:id',
  PARTICIPANT_SETTINGS: '/participant/:id/settings',
  MARKET_NEWS: '/market-news',

  NOTIFICATIONS: '/notifications',

  EXPORTS_PAGE: '/exports',

  DOCUMENT_REQUESTS: '/document-requests',

  CODAT_REDIRECT_PAGE: '/codat-redirect/:companyId',

  GLOBAL_SEARCH: '/search',
  GLOBAL_ERROR: '/error',

  CLIENT_VISUALIZATIONS: '/client-visualizations',
} as const

const BLANK_LAYOUT_ROUTES = [
  ROUTES.LOGIN,
  ROUTES.SIGNUP,
  ROUTES.RESET_PASSWORD,
  ROUTES.PASSWORD_RECOVERY,
  ROUTES.CLIENT_INTEGRATIONS.replace(':id', ''),
  ROUTES.CLIENT_INTAKE.replace(':salesforceId', ''),
  '/reset-password/',
  '/password-setup/',
]

export { ROUTES, BLANK_LAYOUT_ROUTES }
