import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'

import styles from './LCRCommentary.module.scss'
import { ReactComponent as CheveronUp } from '@assets/images/chevron-up.svg'
import { ReactComponent as CheveronDown } from '@assets/images/down-chevron.svg'

import { ILoadingData } from '../../redux/types'
import { IDueDiligence, ILCRCommentary } from '@common/interfaces/dueDiligence'
import Card from '../Common/Card'
import FullscreenModal from '../Common/FullscreenModal'
import { ExpandAndMinimize } from '../Common/Icons'
import LCRCommentaryNoteEditor from './LCRCommentaryNoteEditor'
import { ClientInfoStatus } from '@common/interfaces/client'
import { LCR_COMMENTARY_DEFAULT_CUSTOM_HEADER } from '@common/constants/client'

interface IProps {
  section: string
  dueDiligenceInfo: IDueDiligence
  lcrCommentaryData: { [key: string]: ILoadingData<{ data: ILCRCommentary[] }> }
  listLCRCommentary: (id: string, data: object) => void
  updateLCRCommentary: (id: string, lcrCommentaryId: string, data: object) => Promise<void>
}

const LCRCommentaryLoader = () => {
  return (
    <Grid item xs={12}>
      <div className={styles.field}>
        <Skeleton width={150} height={24} />
      </div>

      <Skeleton width="100%" height={16} />
      <Skeleton width="100%" height={16} />
      <Skeleton width="25%" height={16} />
    </Grid>
  )
}

const LCRCommentaryField = ({
  lcrCommentary: { id, field, helperText, note },
  handleSaveNote,
  readOnly,
  isFullWidth,
}: {
  lcrCommentary: ILCRCommentary
  handleSaveNote: (id: string, values: object) => Promise<void>
  readOnly: boolean
  isFullWidth: boolean
}) => {
  const onUpdate = useCallback((values: object) => handleSaveNote(id, values), [id, handleSaveNote])
  const noteValue = useMemo(
    () => note?.note,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [note.id],
  )

  return (
    <Grid item xl={isFullWidth ? 12 : 6} xs={12} className={styles.fieldWrapper}>
      <div className={styles.field}>{field}</div>
      {helperText && <div className={styles.helperText}>{helperText}</div>}

      <LCRCommentaryNoteEditor
        key={note?.id}
        value={noteValue}
        handleUpdate={onUpdate}
        readOnly={readOnly}
        noteText={note?.noteText}
      />
    </Grid>
  )
}

const LCRCommentary = ({
  section,
  dueDiligenceInfo,
  lcrCommentaryData,
  listLCRCommentary,
  updateLCRCommentary,
}: IProps) => {
  const { id } = useParams<{ id: string }>()

  const [isExpanded, setIsExpanded] = useState(true)
  const [isModalShown, setIsModalShown] = useState(false)

  useEffect(() => {
    listLCRCommentary(id, {
      section,
    })
  }, [id, section, listLCRCommentary])

  const readOnly = useMemo(
    () => dueDiligenceInfo?.clientStatus !== ClientInfoStatus.DueDiligence,
    [dueDiligenceInfo],
  )

  const { isLoading, data } = useMemo(() => {
    const lcrCommentarySectionData = lcrCommentaryData[section] || {
      isLoading: true,
      isSaving: false,
      data: {
        data: [] as ILCRCommentary[],
      },
    }

    return {
      isLoading: lcrCommentarySectionData.isLoading,
      isSaving: lcrCommentarySectionData.isSaving,
      data: lcrCommentarySectionData.data.data || [],
    }
  }, [section, lcrCommentaryData])

  const toggleExpand = useCallback(() => {
    setIsExpanded((isExpanded) => !isExpanded)
  }, [])

  const toggleFullScreenModal = useCallback(() => {
    setIsModalShown((isShown) => !isShown)
  }, [])

  const handleSaveNote = useCallback(
    (lcrCommentaryId: string, note: object) =>
      updateLCRCommentary(id, lcrCommentaryId, { section, note }),
    [id, section, updateLCRCommentary],
  )

  return (
    <FullscreenModal
      isOpen={isModalShown}
      setIsOpen={setIsModalShown}
      classes={{ body: styles.fullScreenModal }}
    >
      <Card noHeaderMargin withBorder={false}>
        <Grid container alignItems="flex-start" rowGap={1.5} mt={2.5}>
          <Grid item xs={12} justifyContent="space-between" display="flex">
            <div className={styles.header} onClick={toggleExpand}>
              {isExpanded ? <CheveronUp /> : <CheveronDown />}
              {LCR_COMMENTARY_DEFAULT_CUSTOM_HEADER[section] || section}
            </div>

            <ExpandAndMinimize
              action={toggleFullScreenModal}
              isExpanded={isModalShown}
              size="small"
            />
          </Grid>

          {isExpanded &&
            (isLoading ? (
              <LCRCommentaryLoader />
            ) : (
              data.map((item) => (
                <LCRCommentaryField
                  key={item.id}
                  lcrCommentary={item}
                  handleSaveNote={handleSaveNote}
                  readOnly={readOnly}
                  isFullWidth={data.length === 1}
                />
              ))
            ))}
        </Grid>
      </Card>
    </FullscreenModal>
  )
}

export default LCRCommentary
