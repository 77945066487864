import React from 'react'

import { ROUTES } from '../../constants/routes'
import TabsWithRoutesMenu from '../Common/TabsWithRoutesMenu'

enum LCRTabs {
  Overview = 'Overview',
  Financials = 'Financials',
  Forecast = 'Forecast',
  Transactions = 'Transactions',
  Receivables = 'Receivables',
  Payables = 'Payables',
  Inventory = 'Inventory',
  CapTable = 'Cap Table',
  FieldExam = 'Field Exam',
}

const TABS_ROUTES = {
  [LCRTabs.Overview]: ROUTES.DUE_DILIGENCE_LOAN_COMMITTEE_REPORT_PAGE,
  [LCRTabs.Financials]: ROUTES.DUE_DILIGENCE_LOAN_COMMITTEE_REPORT_FINANCIALS_PAGE,
  [LCRTabs.Forecast]: ROUTES.DUE_DILIGENCE_LOAN_COMMITTEE_REPORT_FORECAST_PAGE,
  [LCRTabs.Transactions]: ROUTES.DUE_DILIGENCE_LOAN_COMMITTEE_REPORT_TRANSACTIONS_PAGE,
  [LCRTabs.Receivables]: ROUTES.DUE_DILIGENCE_LOAN_COMMITTEE_REPORT_RECEIVABLES_PAGE,
  [LCRTabs.Payables]: ROUTES.DUE_DILIGENCE_LOAN_COMMITTEE_REPORT_PAYABLES_PAGE,
  [LCRTabs.Inventory]: ROUTES.DUE_DILIGENCE_LOAN_COMMITTEE_REPORT_INVENTORY_PAGE,
  [LCRTabs.CapTable]: ROUTES.DUE_DILIGENCE_LOAN_COMMITTEE_REPORT_CAP_TABLE_PAGE,
  [LCRTabs.FieldExam]: ROUTES.DUE_DILIGENCE_LOAN_COMMITTEE_REPORT_FIELD_EXAM_PAGE,
}

const LoanCommitteeReportTabs = () => {
  return <TabsWithRoutesMenu tabs={TABS_ROUTES} isQueryString={false} />
}

export default LoanCommitteeReportTabs
