import React, { useCallback, useMemo, useState, useRef, useEffect } from 'react'
import {
  EditorState,
  convertToRaw,
  convertFromRaw,
  RawDraftContentState,
  CompositeDecorator,
  ContentState,
  RichUtils,
} from 'draft-js'
import Editor from '@draft-js-plugins/editor'
import createLinkifyPlugin from '@draft-js-plugins/linkify'
import createInlineToolbarPlugin from '@draft-js-plugins/inline-toolbar'
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  UnorderedListButton,
  OrderedListButton,
} from '@draft-js-plugins/buttons'
import { diff as deepDiff } from 'deep-object-diff'
import cn from 'classnames'

import '@draft-js-plugins/mention/lib/plugin.css'
import 'draft-js/dist/Draft.css'
import '@draft-js-plugins/inline-toolbar/lib/plugin.css'

import styles from '../Notes/NotesContainer.module.scss'
import lcStyles from './LCRCommentary.module.scss'

import { debounceEventHandler } from '../../helpers/helpers'
import SaveState from '../Common/SaveState'

const linkifyPlugin = createLinkifyPlugin({
  target: '_blank',
  component(props) {
    return <a {...props}>Link</a>
  },
})
interface IProps extends Partial<React.ComponentProps<typeof Editor>> {
  value?: RawDraftContentState
  handleUpdate: (values: object) => Promise<void>
  noteText?: string
  key: string
}

const NotesEditor = ({ value, readOnly = false, handleUpdate, noteText, key }: IProps) => {
  const valueRef = useRef(value)
  const [isEditMode, setIsEditMode] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  const [editorState, setEditorState] = useState(() => {
    return EditorState.createEmpty()
  })

  useEffect(() => {
    if (value && Object.keys(value).length > 0) {
      setEditorState((editorState) =>
        EditorState.push(editorState, convertFromRaw(value), 'insert-fragment'),
      )
    }
  }, [value])

  const isEditing = useMemo(() => handleUpdate && !readOnly, [readOnly, handleUpdate])

  const onUpdate = useCallback(
    async (noteContentJson: any) => {
      // Ignore empty changes
      if (valueRef?.current && !Object.keys(deepDiff(valueRef.current, noteContentJson)).length) {
        return
      }

      setIsSaving(true)
      await handleUpdate(noteContentJson)
      valueRef.current = noteContentJson
      setIsSaving(false)
    },
    [handleUpdate],
  )

  const debounceHandleUpdateNotes = useMemo(
    () => debounceEventHandler(async (noteContentJson: any) => onUpdate(noteContentJson), 1000),
    [onUpdate],
  )

  const onEditorChange = useCallback(
    (editorState: any) => {
      setEditorState(editorState)
      isEditing &&
        editorState.getLastChangeType() &&
        debounceHandleUpdateNotes(convertToRaw(editorState.getCurrentContent()))
    },
    [isEditing, debounceHandleUpdateNotes],
  )

  const { InlineToolbar, plugins, decorator } = useMemo(() => {
    const inlineToolbarPlugin = createInlineToolbarPlugin({
      theme: {
        buttonStyles: {
          buttonWrapper: styles.toolbarButtonWrapper,
          button: styles.toolbarButton,
          active: styles.toolbarButtonActive,
        },
        toolbarStyles: { toolbar: styles.toolbarStyles },
      },
    })
    const { InlineToolbar } = inlineToolbarPlugin

    const plugins = [linkifyPlugin, inlineToolbarPlugin]

    return {
      plugins,
      InlineToolbar,
      decorator: new CompositeDecorator(
        [linkifyPlugin, inlineToolbarPlugin]
          .reduce((acc, plugin) => (plugin.decorators ? [...acc, ...plugin.decorators] : acc), [])
          .filter((_, index) => index !== 1),
      ),
    }
  }, [])

  useEffect(() => {
    if ((handleUpdate || (readOnly && value)) && editorState === EditorState.createEmpty()) {
      if (Object.keys(value).length > 0) {
        setEditorState(EditorState.createWithContent(convertFromRaw(value), decorator))
      }
      if (noteText) {
        setEditorState(EditorState.createWithContent(ContentState.createFromText(noteText)))
      }
    }
  }, [noteText, decorator, readOnly, value, setEditorState, handleUpdate, editorState])

  const ref = useRef<Editor>(null)

  const focusEditor = useCallback(() => {
    ref.current!.focus()
    setIsEditMode(true)
  }, [])

  const onBlur = useCallback(() => {
    setIsEditMode(false)
  }, [])

  const handleTab = useCallback(
    (e) => {
      e.preventDefault()

      const selection = editorState.getSelection()
      const contentState = editorState.getCurrentContent()
      const currentBlockKey = selection.getStartKey()
      const currentBlock = contentState.getBlockForKey(currentBlockKey)
      const currentBlockType = currentBlock.getType()

      if (currentBlockType === 'ordered-list-item' || currentBlockType === 'unordered-list-item') {
        onEditorChange(RichUtils.onTab(e, editorState, 4))
      }
    },
    [editorState, onEditorChange],
  )

  const handleKeyCommand = useCallback(
    (command, editorState) => {
      const newState = RichUtils.handleKeyCommand(editorState, command)
      if (newState) {
        onEditorChange(newState)
        return 'handled'
      }
      return 'not-handled'
    },
    [onEditorChange],
  )

  return (
    <div className={lcStyles.editorWrapper}>
      <div
        className={cn(lcStyles.editor, {
          [lcStyles.editMode]: isEditMode,
          [styles.editor]: !readOnly,
          [styles.readOnly]: readOnly,
        })}
        onClick={focusEditor}
      >
        <Editor
          editorKey={key}
          editorState={editorState}
          onTab={handleTab}
          onChange={onEditorChange}
          plugins={plugins}
          spellCheck
          ref={ref}
          readOnly={readOnly}
          blockStyleFn={() => styles['public-DraftStyleDefault-block']}
          handleKeyCommand={handleKeyCommand}
          onBlur={onBlur}
        />
      </div>

      <div className={styles.toolbarContainer}>
        <InlineToolbar>
          {(externalProps) => (
            <div className={styles.toolbarButtonWrapper}>
              <div className={styles.toolbarButtonContainer}>
                <BoldButton {...externalProps} />
                <ItalicButton {...externalProps} />
                <UnderlineButton {...externalProps} />
                <UnorderedListButton {...externalProps} />
                <OrderedListButton {...externalProps} />
              </div>
            </div>
          )}
        </InlineToolbar>
      </div>

      {isEditing && <SaveState isSaving={isSaving} isSaved />}
    </div>
  )
}

export default NotesEditor
